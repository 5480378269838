import styled from 'styled-components';

import { ReactComponent as peopleIcon } from 'assets/icons/people.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg';
import Button from 'components/common/Button';
import { Container, Column, Row, Header } from 'components/Financial/Layout/Tables';

import EmptyState from '../EmptyState';

const RESOURCE_SETUP_COLUMNS = '2fr 1.5fr 1fr 1fr 1fr';

export const ResourceSetupContainer = styled.div`
  flex-shrink: 4;
  min-width: 28rem;
  padding: 2.4rem 0;
  width: 100%;
`;

export const StyledColumn = styled(Column)`
  border-right: ${({ theme, separator }) => separator && `2px ${theme.new.colors.brand} solid`};
  background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.white};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: ${({ paddingLeft }) => paddingLeft};

  &:hover {
    background-color: ${({ theme, isEditing }) => isEditing && theme.new.colors.neutral100};
  }
`;

export const StyledPeopleIcon = styled(peopleIcon)`
  height: 1.8rem;
  width: 2.2rem;

  path {
    stroke: ${({ theme }) => theme.new.colors.brand};
  }
`;

export const StyledEmptyState = styled(EmptyState)`
  border-radius: 0;
  border-radius-bottom-left: 4px;
`;

export const StyledRow = styled(Row)`
  display: grid;
  grid-template-columns: ${RESOURCE_SETUP_COLUMNS};
  padding: 0;

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }
`;

export const UpdateResourcesButton = styled(Button)`
  ${({ theme }) => theme.new.typography.p5SemiBold};
  border: 0;
  color: ${({ theme }) => theme.new.colors.P500};
  padding: 1rem 1.6rem;
`;

export const EditResourcesButton = styled(Button)`
  ${({ theme }) => theme.new.fontWeights.semiBold};
  min-width: auto;
  padding: 0.8rem 1.2rem;

  path {
    stroke: ${({ theme }) => theme.new.colors.black};
  }
`;

export const ResourceTableContainer = styled(Container)`
  margin-top: 0.3rem;
`;

export const ResourceSetupHeader = styled(Header)`
  display: grid;
  grid-template-columns: ${RESOURCE_SETUP_COLUMNS};
`;

export const ResourceSectionTitle = styled.h3`
  ${({ theme }) => theme.new.typography.h3};
  align-items: center;
  color: ${({ theme }) => theme.new.colors.black};
  display: flex;
  justify-content: space-between;
  min-height: 3.6rem;
  padding-bottom: 0.8rem;
  padding-right: 1.6rem;
  width: 100%;
`;

export const GeneralDiscountButton = styled(Button)`
  ${({ theme }) => theme.new.typography.semiBold};
  gap: 0.8rem;
  max-height: 3.6rem;
  max-width: 20rem;
  padding: 1.2rem;
  & > svg > * {
    fill: transparent;
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  path {
    stroke: ${({ theme }) => theme.new.colors.black};
    stroke-width: 1.67px;
  }
`;
