export default {
  //  common upper case
  'common.upper.signUp': 'SIGN UP',

  //  common lower case
  'common.lower.days': 'days',
  'common.lower.hours': 'hours',
  'common.lower.months': 'months',
  'common.lower.ongoing': 'ongoing',
  'common.lower.to': 'to',
  'common.lower.years': 'years',

  //  common first capitalize
  'common.cap.assignments': 'Assignments',
  'common.cap.billingUnit.daily': 'Daily',
  'common.cap.billingUnit.hourly': 'Hourly',
  'common.cap.billingUnit': ' Billing unit',
  'common.cap.confirmResource': 'Confirm resource',
  'common.cap.confirmPosition': 'Confirm position',
  'common.cap.createStatus': 'Create status',
  'common.cap.deleteAssignment': 'Delete assignment',
  'common.cap.deleteStatus': 'Delete status',
  'common.cap.editAssignment': 'Edit assignment',
  'common.cap.endDate': 'End date',
  'common.cap.englishLevel': 'English level',
  'common.cap.insights': 'Insights',
  'common.cap.legacy': 'Legacy',
  'common.cap.mainSkill': 'Main skill',
  'common.cap.mainSkills': 'Main skills',
  'common.cap.name': 'Name',
  'common.cap.newAssignment': 'New assignment',
  'common.cap.newResource': 'New resource',
  'common.cap.nonBillable': 'Non billable',
  'common.cap.passwordConfirmation': 'Password confirmation',
  'common.cap.pendingConfirmation': 'Pending confirmation',
  'common.cap.projectCode': 'Code',
  'common.cap.projected': ' Projected',
  'common.cap.projectName': 'Name',
  'common.cap.rateValue': 'Rate value',
  'common.cap.reportsTo': 'Reports to',
  'common.cap.searchGroupRole': 'Search group',
  'common.cap.searchSkill': 'Search skill',
  'common.cap.searchRole': 'Search role',
  'common.cap.searchResource': 'Search for resources',
  'common.cap.secondarySkill': 'Secondary skill',
  'common.cap.signIn': 'Sign in',
  'common.cap.startDate': 'Start date',
  'common.cap.updateStatus': 'Update status',
  'common.cap.utilization': 'Utilization',
  'common.cap.scheduledVacations': 'Scheduled vacations',
  'common.cap.technicalSkills': 'Technical skills',
  'common.cap.allocation': 'Allocation',
  'common.cap.softSkills': 'Soft skills',
  'common.cap.industryExperience': 'Industry experience',
  'common.cap.interests': 'Interests',
  'common.cap.pastProjects': 'Past projects',
  'common.cap.figmaBio': 'Figma bio',
  'common.cap.onlyMainSkills': 'Only main skills',
  'common.cap.quarter': 'Quarter',
  'common.cap.searchPeople': 'Search people',
  'common.cap.projectType': 'Project type',
  'common.cap.projectStatus': 'Project status',
  'common.cap.assignmentStatus': 'Assignment status',
  'common.cap.startingDate': 'Starting date',
  'common.cap.allDepartments': 'All departments',
  'common.cap.allSkills': 'All skills',
  'common.cap.allRoles': 'All Roles',
  'common.cap.yearlyValue': 'Yearly value',
  'common.cap.management': 'Management',
  'common.cap.teamMembers': 'Current team members',
  'common.cap.oldAssignments': 'Old team members',

  //  common
  'common.accept': 'Accept',
  'common.actualRevenue': 'Actual Revenue',
  'common.addAssignments': 'Add Assignments',
  'common.addSkill': 'Add Skill',
  'common.available': 'Available',
  'common.allocation': 'Allocation',
  'common.allocationTimeline': 'Allocation Timeline',
  'common.area': 'Area',
  'common.assignResource': 'Assign Resource',
  'common.availability': 'Availability',
  'common.awaitingStart': 'Awaiting Start',
  'common.backfillPosition': 'Backfill Position',
  'common.billable': 'Billable',
  'common.billable.yes': 'Yes',
  'common.billable.no': 'No',
  'common.cancel': 'Cancel',
  'common.capacityAndUtilization': 'Capacity & Utilization',
  'common.client': 'Client',
  'common.clientName': 'Client Name',
  'common.close': 'Close',
  'common.code': 'Code',
  'common.confirmed': 'Confirmed',
  'common.confirmCandidate': 'Confirm Candidate',
  'common.cost': 'Cost',
  'common.createdAt': 'Created At',
  'common.current': 'Current',
  'common.currentAllocation': 'Current Allocation',
  'common.currentDay': 'Current Day',
  'common.custom': 'Custom',
  'common.customer': 'Customer',
  'common.dates': 'Dates',
  'common.delete': 'Delete',
  'common.deleteAssignment': 'Delete Assignment',
  'common.delivered': 'Delivered',
  'common.deliveryOwner': 'Delivery Owner',
  'common.department': 'Department',
  'common.departments': 'Departments',
  'common.discount': 'Discount',
  'common.discountOf': 'Discount of {discount}%',
  'common.editAssignment': 'Edit Assignment',
  'common.email': 'Email',
  'common.exitDate': 'Exit Date',
  'common.experience': 'Experience',
  'common.export': 'Export',
  'common.financial': 'Financial',
  'common.forecasting': 'Forecasting',
  'common.from': 'From',
  'common.future': 'Future',
  'common.group': 'Group',
  'common.industry': 'Industry',
  'common.keepDuration': 'Keep Duration',
  'common.keepEditing': 'Keep Editing',
  'common.loading': 'Loading...',
  'common.logout': 'Log Out',
  'common.mainSkill': 'Main Skill',
  'common.manager': 'Manager',
  'common.margin': 'Margin',
  'common.monthly': 'Monthly',
  'common.myProjects': 'My Projects',
  'common.myReports': 'My Reports',
  'common.NA': 'N/A',
  'common.newAssignment': 'New Assignment',
  'common.newProject': 'New Project',
  'common.nonBillable': 'Non Billable',
  'common.notAssigned': 'Not Assigned',
  'common.note': 'Note',
  'common.notes': 'Notes',
  'common.notifications': 'Notifications',
  'common.notify': 'Notify',
  'common.ongoing': 'Ongoing',
  'common.open': 'Open',
  'common.others': 'Others',
  'common.overassigned': 'Overassigned',
  'common.overview': 'Overview',
  'common.past': 'Past',
  'common.projectsOverview': 'Projects Overview',
  'common.projectsTimeline': 'Projects Timeline',
  'common.password': 'Password',
  'common.pending': 'Pending',
  'common.people': 'People',
  'common.period': 'Period',
  'common.project': 'Project',
  'common.projectType': 'Project Type',
  'common.projectFinancial': 'Project Financial',
  'common.projects': 'Projects',
  'common.rate': 'Bill Rate',
  'common.resource': 'Resource',
  'common.resourceDetails': 'Resource Details',
  'common.resourceProfile': 'Resource Profile',
  'common.resources': 'Resources',
  'common.revenue': 'Revenue',
  'common.revenueGoal': 'Revenue Goal',
  'common.role': 'Role',
  'common.requirements': 'Requirements',
  'common.salesExecutive': 'Sales Executive',
  'common.save': 'Save',
  'common.saveChanges': 'Save Changes',
  'common.searchPeople': 'Search People',
  'common.seniority': 'Seniority',
  'common.serviceType': 'Service Type',
  'common.skill': 'Skill',
  'common.skills': 'Skills',
  'common.status': 'Status',
  'common.support': 'Support',
  'common.timeline': 'Timeline',
  'common.to': 'To',
  'common.today': 'Today',
  'common.totalProjects': 'Total Projects',
  'common.totalRevenue': 'Total Revenue',
  'common.underContract': 'Under Contract',
  'common.unknown': 'Unknown',
  'common.user': 'User',
  'common.requestBio': 'Request Bio',
  'common.unavailable': 'Unavailable',
  'common.viewBio': 'View Bio',
  'common.viewLegacyBio': 'View Legacy Bio',
  'common.viewDetail': 'View Detail',
  'common.viewProfile': 'View Profile',
  'common.vacations': 'Vacations',
  'common.workingOn': 'Working On',
  'common.yesterday': 'Yesterday',
  'common.search': 'Search',
  'common.assignmentStatus': 'Assignment Status',
  'common.type': 'Type',
  'common.training': 'Training',
  'common.conversion': 'Conversion',
  'common.demand': 'Demand',
  'common.scheduled': 'Scheduled',
  'common.scheduledUtilization': 'Scheduled Utilization',
  'common.billingCapacity': 'Billing Capacity',
  'common.FTE': 'FTE',
  'common.month': 'Month',
  'common.bench': 'Bench',
  'common.benchFTE': 'Bench FTE',
  'common.benchComposition': 'Bench \n Composition',
  'common.totalBench': 'Total Bench',
  'common.general': 'General',
  'common.clients': 'Clients',
  'common.industries': 'Industries',
  'common.startDate': 'Start Date',
  'common.endDate': 'End Date',
  'common.projectCode': 'Project Code',
  'common.projectName': 'Project name',
  'common.settings': 'Settings',
  'common.edit': 'Edit',
  'common.projectLeaders': 'Project Leaders',
  'common.createdOn': 'Created on',
  'common.lastEdited': 'Last edited',
  'common.projectOverview': 'Project Overview',
  'common.projectManager': 'project manager',
  'common.lead': 'lead',
  'common.productManager': 'product manager',

  'status.active': 'Active',
  'status.confirmed': 'Confirmed',
  'status.confirmedOrActive': 'Active / Confirmed',
  'status.finished': 'Finished',
  'status.maintenance': 'Maintenance',
  'status.new': 'New',
  'status.onHold': 'On Hold',
  'status.planned': 'Planned',
  'status.warranty': 'Warranty',

  'serviceType.discovery': 'Discovery',
  'serviceType.fullProduct': 'Full Product',
  'serviceType.staffAugmentation': 'Staff Augmentation',

  'english.a1Basic': 'A1 Basic',
  'english.a2Basic': 'A2 Basic',
  'english.b1Intermediate': 'B1 Intermediate',
  'english.b2Intermediate': 'B2 Upper Intermediate',
  'english.c1Advance': 'C1 Advance',
  'english.c2Advance': 'C2 Advance',
  'english.nativeSpeaker': 'Native Speaker',

  'timeline.toggleUnassignedSkillsShown': 'Show unassigned skills',

  'timeline.fullAvailable': 'Fully Available',
  'timeline.partialAvailable': 'Partially Available',
  'timeline.unavailable': 'Unavailable',
  'timeline.overassigned': 'Over Assigned',
  'timeline.vacations': 'Vacations',
  'timeline.training': 'Training',
  'timeline.conversion': 'Conversion',
  'timeline.hireAndExit': 'Hire / Exit Date',
  'timeline.hireDate': 'Start to Work on',
  'timeline.exitDate': 'Exit Date',

  // Project form
  'projectForm.name.placeholder': 'Insert a project name',
  'projectForm.client.placeholder': 'Search client',
  'projectForm.code.placeholder': 'Insert project code',
  'projectForm.serviceType.placeholder': 'Select project type',
  'projectForm.salesExecutive.placeholder': 'Search sales executive',
  'projectForm.deliveryOwner.placeholder': 'Search delivery owner',
  'projectForm.manager.placeholder': 'Search engineer manager',
  'projectForm.status.placeholder': 'Select status',
  'projectForm.industry.placeholder': 'Select industry',
  'projectForm.notes.placeholder': 'Add a note about this project...',

  'projectForm.section.basicInfo': 'Basic info',
  'projectForm.section.projectLeaders': 'Project leaders',
  'projectForm.section.notes': 'Notes',

  'login.title': 'Sign into your account',
  'login.form.genericError': 'Incorrect username or password.',

  'notification.acknowledge.pm': 'PM already notified',
  'notification.notAcknowledge.pm': "Notify PM you're working on it?",

  'emptyState.icon': 'empty state icon',
  'emptyState.resourcesSetup.subtitle': 'We are still working on the team composition',
  'emptyState.resourcesSetup.title': 'No assignments yet',
  'emptyState.title.resources': 'No Resources to display',
  'emptyState.title.relations': 'No Relations to display',
  'emptyState.subtitle.resources': 'There are no resources to display using your filter criteria.',
  'emptyState.subtitle.relations': 'There are no relations to display using your filter criteria.',
  'emptyState.title.projects': 'No Projects to display',
  'emptyState.subtitle.projects': 'There are no projects to display using your filter criteria.',
  'emptyState.underConstruction.subtitle': 'This page is under construction',
  'emptyState.subtitle.roles': 'It looks like there are no roles to display.',
  'emptyState.title.roles': 'No Roles to display',
  'emptyState.title.finishedAssignments': 'No Finished Assignments to display',
  'emptyState.subtitle.finishedAssignments':
    'When assignments are finished, they will be displayed here',

  'assignment.backfillPosition.tooltip':
    'This resource is currently backfilling \n the original resource assigned.',
  'assignment.form.copy': 'Save & Add More',
  'assignment.form.keepEndDate': 'Keep End Date',
  'assignment.form.note.placeholder': 'Add a note about this assignment...',
  'assignment.form.percentage': 'Allocation %',
  'assignment.form.workHours': 'Hours/day',
  'assignment.newProjectCompleteLater': 'Save and Complete later',
  'assignment.newToProject': 'Assign to project',
  'assignment.form.skills.placeholder': 'Search skill',
  'assignment.form.seniority.placeholder': 'Select Seniority',
  'assignment.form.role.placeholder': 'Select Role',
  'assignment.form.resource.placeholder': 'Search resource',
  'assignment.form.percentage.placeholder': 'Rate value',
  'assignment.form.workHours.placeholder': 'i.e.: 7,5',
  'assignment.form.rate': 'Daily bill rate for FTE',
  'assignment.form.section.assignmentsAndDates': 'Assignments and dates',
  'assignment.form.section.resourceAndSkills': 'Resource and skills',
  'assignment.form.section.notes': 'Notes',
  'assignment.form.capacity.tooltip':
    'Resource working hours considering expected percentage of the role',

  'editQuickAssignment.confirmDirectly': 'Confirm Resource Directly',
  'editQuickAssignment.subtitle': 'Please confirm Start and End dates',

  'projectStatus.confirmDelete': 'Confirm status deletion?',
  'projectStatus.updateAssignments': 'Confirm all the assignments',

  'utilization.areas.design': 'Design',
  'utilization.areas.engineering': 'Engineering',
  'utilization.areas.operationTeam': 'Operations',
  'utilization.areas.productOwner': 'Product Owner',
  'utilization.areas.shortName.design': 'Design',
  'utilization.areas.shortName.engineering': 'Eng',
  'utilization.areas.shortName.operationTeam': 'Ops',
  'utilization.areas.shortName.productOwner': 'Prod',

  'template.assignment.resourceCapacity': 'Capacity: {capacity}',
  'template.availableFor': '{days, plural, =0 {< 1 Day} one {# Day} other {# Days}}',
  'template.confirmName': 'Do you want to Confirm {name}?',
  'template.connectors.of': '{valA} of {valB}',
  'template.counters.weeks': '{value} weeks',
  'template.daysOnBench': 'Resources with {days}+ days on Bench',
  'template.deleteEntity': 'Are you sure you want to delete the {entity}?',
  'template.editProjectStatus.title': 'Edit status for {projectName}',
  'template.harvestHours.capacityHours': '{harvestHours} of {capacityHours}',
  'template.newProjectStatus.title': 'New status for {projectName}',
  'template.projectName': '{projectName} Project',
  'template.utilization.tooltip': '{text} utilization',
  'template.leaveDate': 'Leaving at {endDate}',
  'template.leaveDate.info': 'Will be leaving the project at {endDate}',
  'template.periodNumber': 'Period #{periodNumber}',
  'template.startingDate': 'Entering {startDate}',
  'template.startingDate.info': 'Will be assigned to the project at {startDate}',
  'template.effectiveDate': 'Effective date: {startDate}',
  'template.vacationReturn': 'Vacations from {startDate} to {returnDate}',
  'template.vacationReturn.info': 'From {startDate} to {endDate} - ({duration}) Workdays',
  'template.editAssignment': 'Assignment for {projectName}',
  'template.newAssignment': 'New assignment for {projectName}',
  'template.notAvailable': 'Not Available',
  'template.topAmount': 'Top {amount}',
  'template.bottomAmount': 'Bottom {amount}',
  'template.top5Name': 'by Top 5 {name}',
  'template.by': 'by {name}',

  //  not found page
  'notFound.status': '404',
  'notFound.title.notAccess': 'Access Denied',
  'notFound.description.notAccess':
    'You do not have the necessary permissions to access this page.',
  'notFound.title.notFound': 'Page Not Found',
  'notFound.description.notFound':
    'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.',

  //  modal
  'modal.note.title': 'Edit Note',
  'modal.note.button': 'Save note',
  'modal.confirm.title': 'Please confirm',
  'modal.confirm.archive.message': 'Are you sure you want to archive the project?',
  'modal.confirm.archive.accept': 'Archive',
  'modal.confirmAssignment.subtitle': 'Please confirm Start and End dates',
  'modal.openPeriod.create.title': 'Create New Period',
  'modal.openPeriod.edit.title': 'Period Info',
  'modal.openPeriod.confirm': 'Create Period',
  'modal.openPeriod.create.confirm': 'Create Period',
  'modal.openPeriod.edit.confirm': 'Save Changes',
  'modal.openPeriod.close.title': 'Close Period',
  'modal.openPeriod.close.message':
    "This action will impact on the project's financial information.",
  'modal.openPeriod.close.accept': 'Close',
  'modal.openPeriod.delete.title': 'Delete period',
  'modal.closedPeriod.delete.title': 'Delete closed period',
  'modal.closedPeriod.delete.message':
    "This action will remove the period's financial information from the project totals.",
  'modal.openPeriod.unsavedChanges.title': 'Discard unsaved changes?',
  'modal.openPeriod.unsavedChanges.discard': 'Discard',
  'modal.softSkill.title': 'Add new soft skill',
  'modal.technicalSkill.title': 'Add new technical skill',
  'modal.addTrainingSkill.title': 'Add Training / Conversion skill',
  'modal.editTrainingSkill.title': 'Edit Training / Conversion skill',

  // Filters
  'filter.clear': 'Clear',
  'filter.apply': 'Apply',
  'filter.currentWeek': 'This week',
  'filter.highlightSkill': 'Highlight skill',
  'filter.status.holiday': 'Holiday',
  'filter.status.sick': 'Sick',
  'filter.status.timeOff': 'Time Off',
  'filter.seniority.junior': 'Junior',
  'filter.seniority.semiSenior': 'Semi-Senior',
  'filter.seniority.senior': 'Senior',
  'filter.availability.range1': '0 - 25%',
  'filter.availability.range2': '26% - 50%',
  'filter.availability.range3': '51% - 75%',
  'filter.availability.range4': '76% - 100%',
  'filter.searchResource.placeholder': 'Search for resources',
  'filter.searchProject.placeholder': 'Search for projects',
  'filter.project.assignmentsStatus': 'Assignments',
  'filter.team': 'Team',
  'filter.location': 'Location',
  'filter.resource.leftCompany': 'Left company',
  'filter.team.engineering': 'Engineering Team',
  'filter.team.productOwner': 'Product Owner Team',
  'filter.team.design': 'Design Team',
  'filter.team.scrumMaster': 'Scrum Master Team',
  'filter.team.operations': 'Operations Team',
  'filter.dashboard.apply': 'Apply filters',
  'filter.dashboard.clear': 'Clear filters',
  'filter.financial.projects': 'Search Projects',

  // Resources
  'resources.benchHours': 'Bench Hours',
  'resources.editResource': 'Edit resource',
  'resources.newResource': 'Add new resource',
  'resource.firstName': 'First name',
  'resource.lastName': 'Last name',
  'resource.fullTime': 'Full Time',
  'resource.freelancer': 'Freelancer',
  'resource.hoursRate': 'Hours Rate',
  'resource.capacityInHours': 'Daily capacity in hs',
  'resource.addSkills': '+ Add skills',
  'resource.removeSkills': ' ',
  'resource.back': 'Back',
  'resource.weeks': 'Weeks',
  'resource.location': 'Country, location',
  'resource.experience.skillsErrorMessage':
    'Please, complete time of experience (years and months) for each skill',
  'resources.seniority.junior': 'Jr',
  'resources.seniority.semiSenior': 'Ssr',
  'resources.seniority.senior': 'Senior',

  // Profile
  'profile.location': 'Location',
  'profile.generalInformation': 'General Information',
  'profile.contract': 'Contract',
  'profile.hours': 'Hour Rate',
  'profile.weekHours': 'Hours p/week',
  'profile.specialist': 'Specialist',
  'profile.position': 'Position',
  'profile.preferences': 'Preferences',
  'profile.industry': 'Industry',
  'profile.employee': 'Employee',
  'profile.freelance': 'Freelance',
  'profile.projects.technology': 'Technology',
  'profile.projects.industry': 'Industry',
  'profile.projects.current': 'Current',
  'profile.projects.time': 'Time',
  'profile.emptyExperiences': 'No experiences were added yet.',
  'profile.emptyProjects': 'No projects were assigned yet.',
  'profile.bioRequest': 'Requested at {date}',
  'profile.softSkills.error': 'The skill {skillName} was already added!',

  // Projects
  'projects.archive': 'Archive',
  'projects.archiveProject': 'Archive Project',
  'projects.edit': 'Edit Project',
  'projects.delete': 'Delete Project',
  'projects.addNewStatus': 'Add new status',
  'projects.editProject': 'Edit project',
  'projects.editStatus': 'Edit status',
  'projects.openAssignments': 'Open Assignments',
  'projects.pendingAssignments': 'Pending Assignments',
  'projects.confirmedAssignments': 'Confirmed Assignments',
  'projects.candidate': 'Candidate',
  'projects.totalConfirmed': 'Total Confirmed:',
  'projects.switch.list': 'List',
  'projects.newAssignment': 'Add Assignment',
  'projects.createdBy': 'Created By',

  // Project detail
  'projectDetail.emptyState.title': 'No project information to display',
  'projectDetail.emptyState.subTitle': 'For now we are not showing project additional information',
  'projectDetails.salesRepresentative': 'Sales\nRepresentative',
  'projectDetails.engineerManager': 'Engineer\nManager',
  'projectDetails.deliveryOwner': 'Delivery\nOwner',
  'projectDetails.details.title': 'Project Detail',
  'projectDetails.details.postAuthor': 'by {author}',

  // Search People
  'searchPeople.peopleSummary.title': 'Project Info',
  'searchPeople.peopleSummary.noNotes': 'No notes given',
  'searchPeople.bestMatches.title': 'Best Matches for this position',
  'searchPeople.bestMatches.matchOverall': 'Match overall',
  'searchPeople.bestMatches.interactions.title': 'Allocation over project time',
  'searchPeople.bestMatches.button.viewProfile': 'View Profile',
  'searchPeople.bestMatches.button.select': 'Select',
  'searchPeople.exploreResources.title': 'Explore all resources',

  // Info
  'info.realHours': 'Harvest Billable Hours vs Planned Billable Hours',
  'info.totalUtilization': 'Scheduled Hours (Billable & Non Billable) vs Total Capacity',
  'info.assignments': 'Scheduled Hours in Billable Projects',
  'info.bench': 'Bench Hours Including Pending Assignments',
  'info.benchForecast': 'Resources Allocation Capacity',

  // Dashboard
  'dashboard.plannedUtilization': 'Planned Utilization',
  'dashboard.actualUtilization': 'Actual Utilization',
  'dashboard.actualAmount': 'Actual amount of billable hours logged',
  'dashboard.totalUtilization': 'Total Utilization',
  'dashboard.harvestBillableHours': 'Harvest Billable Hours',
  'dashboard.assignmentGraph': 'Capacity and Utilization Insights',
  'dashboard.totalCapacity': 'Total Capacity',
  'dashboard.time.rangeType': 'Range type',
  'dashboard.time.selectedLabel': 'Time span',
  'dashboard.time.week': 'Week',
  'dashboard.time.year': 'Year',
  'dashboard.time.custom': 'Custom',
  'dashboard.time.lookAhead': 'Look ahead',
  'dashboard.time.lookAhead.current': 'Current',
  'dashboard.time.lookAhead.1m': '1 Month',
  'dashboard.time.lookAhead.2ms': '2 Months',
  'dashboard.time.lookAhead.3ms': '3 Months',
  'dashboard.time.lookAhead.6ms': '6 Months',
  'dashboard.table.work': 'Work',
  'dashboard.table.benchWithPending': 'Bench Forecast',
  'dashboard.table.overassigned': 'Overassigned',
  'dashboard.requested': 'Requested',
  'dashboard.capacity': 'Capacity',
  'dashboard.percentageBillableCapacity': 'Percentage of the Billable Capacity',
  'dashboard.totalScheduled': 'Total Scheduled',
  'dashboard.unscheduled': 'Unscheduled',
  'dashboard.benchHours': 'Bench Hours',
  'dashboard.assignments': 'Assignments',
  'dashboard.benchForecast': 'Bench Forecast',
  'dashboard.scheduledPending': 'Scheduled Utilization (+Pending Confirmed)',
  'dashboard.billableCapacity': 'Billable Capacity',
  'dashboard.availableCapacity': 'Available Capacity',
  'dashboard.billedHours': 'Billed Hours',
  'dashboard.nonBilledHours': 'Non-Billed Hours',
  'dashboard.tab.reports': 'Reports',
  'dashboard.tab.addProject': 'Create project',
  'dashboard.tab.addNewResource': 'Add new Resource',
  'dashboard.roleUtilization': 'Roles Assignments',
  'dashboard.skillsUtilization': 'Skills Assignments',
  'dashboard.role': 'Roles',
  'dashboard.utilization.byArea': 'Utilization by area',
  'dashboard.people.tab.all': 'All',
  'dashboard.people.tab.available': 'Available',
  'dashboard.helper.title': 'Forecast Remarks',
  'dashboard.helper.box.billable.title': 'Billable Capacity:',
  'dashboard.helper.box.billable.description':
    'The amount of capacity we have that is currently billable, though may not be assignable (due to some portion being on internal projects, pending for projects, being in training, being on long-term leave, etc.).',

  // Insights
  'insights.growthCompare.byYear': 'vs Last Year',
  'insights.growthCompare.byQuarter': 'vs Last Q',
  'insights.utilization.bySkill.title': 'by Skill',
  'insights.utilization.department.subtitle': 'of Department Breakdown',
  'insights.utilization.department.table': 'Utilization by Department',

  'insights.financial.monthlyBreakdown.title': 'Monthly Breakdown',
  'insights.financial.monthlyBreakdown.table.columns.month': 'Month',
  'insights.financial.monthlyBreakdown.table.columns.revenue': 'Revenue',
  'insights.financial.monthlyBreakdown.table.columns.costs': 'Cost',
  'insights.financial.monthlyBreakdown.table.columns.margin': 'Margin',

  'insights.bench.benchList.title': 'Bench resources',
  'insights.bench.filter.hidePending': 'Hide Pending Confirmations',
  'insights.bench.benchList.table.columns.mainInfo': 'Resources',
  'insights.bench.benchList.table.columns.department': 'Department',
  'insights.bench.benchList.table.columns.seniority': 'Seniority',
  'insights.bench.benchList.table.columns.mainSkills': 'Main Skills',
  'insights.bench.benchList.table.columns.currentProjects': 'Current Projects',
  'insights.bench.benchList.table.columns.daysOnBench': 'DoB',
  'insights.bench.benchList.table.columns.availability': 'Month Availability',
  'insights.bench.benchList.table.export.columns.mainInfo': 'Resource Name',
  'insights.bench.benchList.table.export.columns.department': 'Department',
  'insights.bench.benchList.table.export.columns.seniority': 'Seniority',
  'insights.bench.benchList.table.export.columns.mainSkills': 'Main Skills',
  'insights.bench.benchList.table.export.columns.currentProjects': 'Current Projects',
  'insights.bench.benchList.table.export.columns.daysOnBench': 'Days on Bench',
  'insights.bench.benchList.table.export.columns.availability': 'Month Availability',
  'insights.bench.benchList.table.export.columns.pending': 'Pending Assignments',
  'insights.bench.benchList.table.export.columns.salary': 'Salary',
  'insights.bench.benchList.table.export.columns.benchCost': 'Bench Cost',

  // People Tab
  'dashboard.people.table.name': 'Name',
  'dashboard.people.table.harvest': 'Harvest billable hours',
  'dashboard.people.table.availableFor': 'Available for',
  'dashboard.people.table.bench': 'Bench Capacity',
  'dashboard.people.table.assignments': 'Assignments',
  'dashboard.people.available.infoTooltip':
    'This is the percentage of non-assigned hours over the total capacity hours for this set of resources.',
  'dashboard.people.FTE.infoTooltip':
    'This is the net amount of non-assigned hours for the resources reached by the selected filter, represented in full-time employees',

  'dashboard.people.summary.noResources': 'No Resources',

  // Dashboard Reports Tab
  'dashboard.reports.sections.insights': 'Insights',
  'dashboard.reports.sections.benchBreakdown': 'Bench Breakdown',

  // Dashboard Breakdown
  'dashboard.breakdown.avg': 'AVG time on Bench:',
  'dashboard.breakdown.table.amountDays': 'Amount of Days',
  'dashboard.breakdown.section.titleAvailableCapacity': 'Available Capacity by Technology',
  'dashboard.breakdown.section.availableChart': 'Technology',

  //  financial
  'financial.tab.reporting': 'Reporting',
  'financial.overview.projectDuration': 'Project Duration:',
  'financial.overview.totalPeople': 'Total people: ',
  'financial.overview.billed': 'Billed to Date',
  'financial.overview.billed.info': `It represents how much we have billed from the client's budget, calculated as the sum of all closed periods amounts.`,
  'financial.overview.remainingRevenue.info': `The client's budget that we have not billed yet, calculated as the sold revenue minus the amount of all closed periods.`,
  'financial.overview.clientBudget.info': `It represents the client's budget that we have available. This value is entered manually.`,
  'financial.overview.cost.info':
    'Total cost incurred by Rootstrap to have the team assigned to the client, calculated as the sum of the cost for all closed periods.',
  'financial.overview.profits.info':
    'The profit to date expressed in monetary units, calculated as the revenue to date minus the cost to date.',
  'financial.overview.margin.info':
    'The margin to date, expressed as the percentage of profit on the revenue to date.',
  'financial.overview.cost': 'Costs to Date',
  'financial.overview.profits': 'Profit to Date',
  'financial.overview.clientBudget': 'Sold Revenue:',
  'financial.overview.remaining': 'Remaining:',
  'financial.overview.remainingRevenue': 'Remaining Revenue:',

  'projectFinancialInfo.form.amount.placeholder': 'Enter amount',
  'projectFinancialInfo.form.clientBudget.label': 'Sold Revenue',
  'projectFinancialInfo.form.cost.label': 'Cost to Date',
  'projectFinancialInfo.form.billed.label': 'Billed to Date',
  'projectFinancialInfo.modal.title': 'Edit Project Financial info',
  'projectFinancialInfo.modal.subTitle': 'Initial project amounts',
  'projectFinancialInfo.form.cost.placeholder': 'Enter team cost',

  'financial.table.setup.cost': 'Cost',

  'financial.table.period.alloc': 'Alloc',
  'financial.table.period.bill': 'Bill',
  'financial.table.period.dateRange': '{startDate} to {endDate}',

  'financial.awaitingResources.label': 'Awaiting Resources:',
  'financial.awaitingResources.open': 'Open positions:',
  'financial.awaitingResources.pending': 'Pending positions:',

  'financial.splitCosts.title': 'Split Costs',
  'financial.splitCosts.updateResources': 'Update Resources',
  'financial.splitCosts.editSetup': 'Edit',
  'financial.splitCosts.tab.resourceSetup': 'Resources Setup',
  'financial.splitCosts.tab.openBillingPeriods': 'Open Billing Periods',
  'financial.splitCosts.updateResources.addGlobalDiscount': 'Add Global Discount',
  'financial.splitCosts.updateResources.globalDiscount': 'Global discount:',
  'financial.splitCosts.updateResources.globalDiscountAmount': 'Global discount: {discount}%',
  'financial.splitCosts.updateResources.confirm': 'Confirm the update',
  'financial.splitCosts.updateResources.confirm.message':
    'Please note that any changes made will impact all currently open periods.',

  'financial.updateResources.title': 'Resources',
  'financial.updateResources.modal.title': 'Updating Resources',
  'financial.updateResources.modal.cancel': 'Decline Updates',
  'financial.updateResources.emptyState.title': 'No new updates.',
  'financial.updateResources.emptyState.subtitle':
    'There are no resource updates or newly added \n resources.',
  'financial.updateResources.modal.section1': 'Updated',
  'financial.updateResources.modal.section2': 'Resources Updated',
  'financial.updateResources.modal.updatingTo': 'Updating To',
  'financial.updateResources.modal.incomingUpdate': 'Incoming Update',

  'financial.openPeriods.title': 'Open Billing Periods',

  'openPeriod.form.name.label': 'Name',
  'openPeriod.form.name.placeholder': 'Enter period name',
  'openPeriod.form.note.label': 'Comment',
  'openPeriod.form.note.placeholder': 'Your comments lives here',
  'openPeriod.addPeriod': 'Add Period',
  'financial.closePeriod.title': 'Closed Periods',
  'financial.closePeriod.download': 'Download PDF Report',
  'financial.closePeriod.table.name': 'Period name',
  'financial.closePeriod.table.periodLength': 'Period length',
  'financial.closePeriod.table.cost': 'Cost',
  'financial.closePeriod.table.generalCosts': 'General Costs',
  'financial.closePeriod.table.billed': 'Billed',
  'financial.closePeriod.table.additional': 'Additional',
  'financial.closePeriod.table.fixedPrice': 'Fixed Price',
  'financial.closePeriod.table.margin': 'Margin',
  'financial.closePeriod.table.note': 'Notes',
  'financial.closePeriod.emptyState': 'There aren’t any closed periods yet.',
  'financial.closePeriod.options.edit': 'Edit',
  'financial.closePeriod.options.close': 'Close Period',

  'financial.period.changePeriodInfo': 'Change period info',
  'financial.period.editBillingPeriod': 'Edit billing period',
  'financial.table.periodDetail.cost': 'Cost',
  'financial.table.periodDetail.alloc': 'Alloc',
  'financial.table.periodDetail.bill': 'Bill',

  'financial.table.total.periodCost': "Period's Cost",
  'financial.table.total.additionalCharges': 'Additional Charges',
  'financial.table.total.periodBilled': 'Billed',
  'financial.table.total.periodMargin': 'Margin',
  'financial.table.total.additional': 'Additional $',
  'financial.table.total.additional.info':
    'Additional charges that are charged to the client. This is done by outsourcing a service or having to pay for something not budgeted from.',
  'financial.table.total.fixedPrice': 'Fixed Price $',
  'financial.table.total.fixedPrice.info':
    'Fixed price set together with the client in a particular period.',
  'financial.empty.value': 'Update \n required',
  'financial.openPeriods': 'Open Periods',
  'financial.noBillableData.title': 'No billable periods so far',
  'financial.noBillableData.subTitle':
    'You can create one by pressing the Add Period button at the top',

  'financial.resourceSetup.billTotal.daily': 'Bill rate per day',
  'financial.resourceSetup.billTotal.hourly': 'Bill rate per hour',

  // Forecasting
  'forecasting.mainPage.title': 'Company Forecasted Revenue',
  'forecasting.mainPage.projectList': 'Project List',
  'forecasting.mainPage.projectList.currentYear': 'Current Year',
  'forecasting.projectsTotals.status': 'Status:',
  'forecasting.graph.revenueToDate': 'Revenue to date',
  'forecasting.graph.yearRevenueGoal': 'Yearly Revenue Goal',

  // Projects Overview
  'projectsOverview.title': 'Overview',
  'projectsOverview.headers.table.columns.name': 'Project Name',
  'projectsOverview.headers.table.columns.status': 'Status',
  'projectsOverview.headers.table.columns.margin': 'Margin',
  'projectsOverview.headers.table.columns.projectType': 'Project Type',
  'projectsOverview.headers.table.columns.startDate': 'Start date',
  'projectsOverview.headers.table.columns.endDate': 'End date',
  'projectsOverview.headers.table.columns.assignments': 'Assignments',
  'projectOverview.filters.search': 'Search project',
  'projectsOverview.detail.table.columns.assignment': 'Assignment',
  'projectsOverview.detail.table.columns.status': 'Status',
  'projectsOverview.detail.table.columns.role': 'Role',
  'projectsOverview.detail.table.columns.allocation': 'Allocation',
  'projectsOverview.detail.table.columns.seniority': 'Seniority',
  'projectsOverview.detail.table.columns.startDate': 'Start date',
  'projectsOverview.detail.table.columns.endDate': 'End date',
  'projectsOverview.detail.table.columns.margin': 'Margin',
  'projectOverview.filter.hideFinished': 'Hide Finished Projects',

  //  my reports profile
  'reportsProfile.trainingSkill': 'Training / Skill Conversion',

  //  breadcrumbs

  'breadcrumbs.list': 'List View',
  'breadcrumbs.financialProjectDetails': 'Projects List',

  // reports
  'reports.hoursWeek': 'Hours per week',
  'reports.tab.skillSet': 'Skillset',
  'reports.tab.projects': 'Projects',
  'reports.resource.editDetails.title': 'Edit resource details',
  'reports.resource.editDetails.bioLink.placeholder': 'www.figma.com',
  'reports.resource.editDetails.addSkill': 'Add another skill',
  'reports.resource.section.trainingIn': 'Training in',
  'reports.resource.section.skillConversion': 'Skill conversion in',

  'form.skillTechnology': 'Skill/Technology',

  // settings
  'settings.accessRoles': 'Access Roles',
  'settings.accessRoles.searchResource': 'Search Resources here...',
  'settings.table.fullName': 'Resource Name',
  'settings.table.group': 'Roles',
  'settings.table.temporalGroups': 'Temporary Role',
  'settings.table.actions': 'Actions',
  'settings.table.actions.addRole': 'Add Role',
  'settings.table.actions.manageRoles': 'Manage Roles',
  'settings.table.startDate': 'Start date',
  'settings.table.endDate': 'End date',
  'settings.groupTable.addGroup': 'Add New Relation',
  'settings.groupTable.deleteRole': 'Delete Relation',
  'settings.groupTable.departmentName': 'Department',
  'settings.groupTable.expectedPercentage': 'Expected Percentage',
  'settings.groupTable.group': 'Group',
  'settings.groupTable.roleName': 'Role Name',
  'settings.accessRoles.searchRole': 'Search Role Name here...',
  'settings.addGroupRole.title': 'Add New Relation',
  'settings.addRole.title': 'Add Roles for {fullName}',
  'settings.editGroupRole.title': 'Edit Relation',
  'settings.deleteGroupRole.confirmation':
    'You are about to delete the relation between {group} & {role}, do you want to proceed?',
  'settings.editRole.title': 'Edit Role for {fullName}',
  'settings.manageRole.title': 'Manage Roles for {fullName}',
  'settings.addRole.form.group': 'Group *',
  'settings.addRole.form.role': 'Role *',
  'settings.addRole.form.roleExpires': 'Role Expires',
  'settings.manageRole.current': 'Current Roles',
  'settings.manageRole.history': 'Roles History',
  'settings.accessMatrix': 'Access Matrix',
  'settings.mappingRoles': 'Mapping Roles',

  // errors
  'email.presence': 'You must enter an email to continue',
  'email.invalid': 'You must enter a valid email',
  'password.presence': 'You must enter a password to continue',
  'passwordConfirmation.presence': 'You must enter a password confirmation to continue',
  'passwordConfirmation.equality': 'Your password confirmation must be equal to the password',
  'startEndDate.order': 'Start date must be prior to end date',
  'assignment.role.required': 'You must select a role',
  'assignment.percentage.max': 'Percentage must be in 1-100',
  'assignment.personHasNoExperience': 'The resource has no experience in:',
  'assignment.capacity.max': 'The resource has full capacity',
  'assignment.resourceAlreadyAssigned': 'The resource is already assigned to this project',
  'location.presence': 'You must select a Location to continue',
  'department.presence': 'You must select a Department to continue',
  'role.presence': 'Select one to continue',
  'seniority.presence': 'Select one to continue',
  'skills.presence': 'You must complete all skills to continue',
  'projectName.presence': 'You must enter a Project Name to continue',
  'projectCode.presence': 'You must enter a Project Code to continue',
  'customer.presence': 'You must select a Customer to continue',
  'serviceType.presence': 'You must select a Service Type to continue',
  'status.presence': 'You must select a Status to continue',
  'tentativeProjectDates.presence': 'You must provide valid project tentative dates',
  'resource.capacityHours.range': 'Must be >0 and <7.5',
  'project.clientBudget.range': 'Must be greater than or equal to 0',
  'project.cost.range': 'Must be greater than or equal to 0',
  'project.billed.range': 'Must be greater than or equal to 0',
  'periodName.presence': 'You must enter a period name to continue',
  'englishLevel.presence': 'You must enter an english level to continue',
  'softSkill.presence': 'You must enter a soft skill to continue',
  'technicalSkill.presence': 'You must enter a technical skill to continue',
  'years.presence': 'The years value must be between 0 and 99',
  'months.presence': 'The months value must be between 0 and 11',

  // date
  'date.years': '{years, plural, =0 {} one {# year} other {# years}}',
  'date.months': '{months, plural, =0 {Less than 1 month} one {# month} other {# months}}',

  // basic permissions denied
  'basic.permissions.title': `Oops! It looks like you don't have access to see any data`,
  'basic.permissions.subTitle': `No worries, though! You can send a quick message over to our operations team, and they'll get you sorted out in no time.`,
  'basic.permissions.thanks': 'Thanks for your patience and understanding!'
};
