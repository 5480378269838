import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5rem;
`;

export const Button = styled.div`
  margin-left: auto;
`;
